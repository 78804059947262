import { keyframes } from '@emotion/react';
import { css } from 'twin.macro';

export const fillNone = css`
  fill: none;
`;

export const logoStrokeSize = css`
  stroke-miterlimit: 10;
  stroke-width: 18.5px;
`;

export const logoSquareBorder = css`
  stroke-dasharray: 1458.87;
  stroke-dashoffset: 1458.87;
`;

export const dashoffsetZero = css`
  stroke-dashoffset: 0;
`;

// Animations
const drawKeyframe = keyframes`
  to {
    stroke-dashoffset: 0;
  }
`;

export const draw = css`
  animation: ${drawKeyframe} 1s 0.25s cubic-bezier(0.445, 0.05, 0.55, 0.95)
    forwards;
`;

/* ----------------------------------------------
 * The following animations were generated by Animista
 * w: http://animista.net, t: @cssanimista
 *
 * slide-in-fwd-center
 * bounce-in-top
 * ----------------------------------------
 */

const slideInFwdCenterKeyframe = keyframes`
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0);
    opacity: 1;
  }
`;

export const slideInFwdCenter = css`
  animation: ${slideInFwdCenterKeyframe} 1.75s ease-in 2.5s forwards;
`;

const bounceInTopKeyframe = keyframes`
  0% {
    transform: translateY(-500px);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  38% {
    transform: translateY(0);
    animation-timing-function: ease-out;
    opacity: 1;
  }

  55% {
    transform: translateY(-65px);
    animation-timing-function: ease-in;
  }

  72% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  81% {
    transform: translateY(-28px);
    animation-timing-function: ease-in;
  }

  90% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }

  95% {
    transform: translateY(-8px);
    animation-timing-function: ease-in;
  }

  100% {
    transform: translateY(0);
    animation-timing-function: ease-out;
  }
`;

export const bounceInTop = css`
  animation: ${bounceInTopKeyframe} 1s 1.25s both;
`;
